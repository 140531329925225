import { property } from 'lit/decorators.js';
import { NNBase, html } from '@mch/nn-web-viz/dist/packages/base/Base';

import './empathy-engine-shell';
import { authFunctionsClient } from './modules/functions/client';
import { connect, store } from './state/store';
import { setUser } from './state/slices/user';
import { setaccessToken } from './state/slices/token';
import { setAccounts, setCurrentAccount } from './state/slices/appConfig';

import { Notification } from '@vaadin/notification/vaadin-notification.js';
import { setClaims, ClaimsProductType } from './state/slices/claims';

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';
import { msalConfig } from './authConfig';

import {
  getClaimsAccountsFromServer,
  getDefaultAccountFromClaims,
  setDataToStore,
} from './modules/claims';

import { ProjectsService } from './service/projects';

import './components/no-access/ee-no-access';
import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-spinner';

const LOGIN_REQUEST = Object.freeze({
  scopes: [
    'openid',
    'profile',
    'email',
    `api://${process.env.CLIENT_ID}/User.Read`,
    // "api:new-client_id/.default"
  ], // Add necessary scopes
  claims: JSON.stringify({
    id_token: {
      email: { essential: true },
    },
  }),
});
class AuthShell extends connect(store)(NNBase) {
  @property({ type: Object }) user;
  @property({ type: String }) _authToken;
  @property({ type: Object }) _claimsData;
  @property({ type: Boolean }) _loggingIn: boolean = false;
  @property({ type: Boolean }) _noAccess: boolean = false;

  private _projectService: ProjectsService;

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  constructor() {
    super();

    this._loginWithMsAd();
    this._projectService = new ProjectsService();
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('_claimsData')) {
      if (this._claimsData != null) {
        this._loggingIn = false;
      }
    }
  }

  stateChanged(_state): void {
    if (_state?.appConfig.value) {
      this._claimsData = _state.appConfig.value;
    }
  }

  get isLoggedIn() {
    return !!this._authToken && !!this._claimsData;
  }

  async _getMsAdClient() {
    const msalInstance = new PublicClientApplication(msalConfig);

    await msalInstance.initialize();
    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event: any) => {
      if (event == null) return;

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = event.payload?.account;
        msalInstance.setActiveAccount(account);
      }
    });

    return msalInstance;
  }

  async _saveLoginInformation(result) {
    if (!result) return;

    const userData = JSON.parse(atob(result?.accessToken.split('.')[1]));

    store.dispatch(
      setUser({
        nickname: `${userData?.given_name.replace(
          /([a-z])([A-Z])/g,
          '$1 $2'
        )} ${userData?.family_name}`,
        picture: null,
      })
    );
    store.dispatch(setaccessToken(result?.accessToken));

    this._authToken = result?.accessToken;

    await this._fetchTokens();
  }

  async _loginPopup(instance) {
    const result = await instance.loginPopup(LOGIN_REQUEST);

    this._saveLoginInformation(result);
  }

  async _loginWithMsAd() {
    this._loggingIn = true;
    const instance = await this._getMsAdClient();

    let result;

    try {
      result = await instance.acquireTokenSilent(LOGIN_REQUEST);
    } catch (error) {
      instance
        .handleRedirectPromise()
        .then((response: AuthenticationResult | null) => {
          if (response) {
            this._saveLoginInformation(response);
          }
        })
        .catch(error => {
          console.error('Redirect error: ', error);
        });

      instance.loginRedirect(LOGIN_REQUEST);
    }

    this._saveLoginInformation(result);
  }

  async _setAccounts(claimsAccounts) {
    store.dispatch(
      setAccounts(
        claimsAccounts.map(account => ({
          id: account.id,
          name: account.name,
        }))
      )
    );
    const result = (await Promise.allSettled(
      claimsAccounts.map(account => {
        return authFunctionsClient.getClaims(account.id);
      })
    )) as { status: 'fulfilled' | 'rejected'; value: any }[];

    const claims: ClaimsProductType[] = result
      .filter(res => res.status === 'fulfilled')
      .map(r => r.value);

    store.dispatch(setClaims(claims));
  }

  _getDefaultAccount() {
    const claims = store.getState().claims.items;
    const claimsProducts: ClaimsProductType = claims.reduce((acc, curr) => {
      const products = curr.products;

      acc = {
        ...acc,
        [curr.accounts]: products,
      };

      return acc;
    }, []);

    const accountsWithAccess = Object.entries(claimsProducts).reduce(
      (acc: Array<string>, [key, value]) => {
        if (
          acc.length === 0 &&
          value.find(product => product.name.toUpperCase() === 'HATE AUDIT')
        ) {
          acc.push(key);
        }

        return acc;
      },
      []
    );

    return claims.find(acc => acc.accounts === accountsWithAccess[0]);
  }

  _setCurrentAccount(account) {
    store.dispatch(
      setCurrentAccount({
        id: account.id,
        name: account.name,
      })
    );
  }

  async _fetchTokens() {
    const resultClaimsAccounts = await getClaimsAccountsFromServer();

    if (resultClaimsAccounts.length === 0) {
      store.dispatch(setAccounts([]));

      this._claimsData = null;

      Notification.show(
        'No account was assigned to this record, please contact your administrator.',
        {
          position: 'bottom-end',
          duration: 0,
          theme: 'error',
        }
      );
    } else {
      const currentAccount = await getDefaultAccountFromClaims(
        resultClaimsAccounts
      );

      if (currentAccount != null) {
        setDataToStore(currentAccount);
        this._claimsData = currentAccount;
      } else {
        this._noAccess = true;
      }
    }

    this._getProjectList();
  }

  async _getProjectList() {
    await this._projectService.getProjectList();
  }

  async _logoutWithMsAd() {
    const instance = await this._getMsAdClient();

    instance.logoutPopup().then(() => {
      this._authToken = null;
      this.user = null;
    });
  }

  render() {
    if (this._noAccess) {
      return html`<ee-no-access></ee-no-access>`;
    }

    if (!this.isLoggedIn && this._loggingIn) {
      return html` <nn-spinner theme="fancy"></nn-spinner>`;
    } else {
      if (!this.isLoggedIn) {
        return html` <div
          style="width: 220px; top: 50%; left:50%; transform: translate(-50%, -50%); position: absolute; color: white; text-align: center;"
        >
          <nn-button style="width: 100%;" @click=${this._loginWithMsAd}>
            Log in
          </nn-button>
        </div>`;
      }
    }

    return html`
      <empathy-engine-shell
        @login=${this._loginWithMsAd}
        @logout=${this._logoutWithMsAd}
        @refresh-tokens=${this._fetchTokens}
      >
      </empathy-engine-shell>
    `;
  }
}

export { AuthShell };
