import { CSSResult, css } from 'lit';
import { registerStyles } from '@vaadin/vaadin-themable-mixin/register-styles';
import { themeStyles } from './index';

interface VaadinCSSResult extends Array<CSSResult> {
  register?: Function;
}

const vaadinStyles: VaadinCSSResult = [
  // body{} for usage directly in HTML
  ...themeStyles,
  css`
    html,
    body,
    :host {
      --lumo-primary-text-color: var(--nn-primary-text-color);
      --lumo-primary-color: var(--nn-accent-color);

      --lumo-body-text-color: white;
      --lumo-base-color: #181b30;
      --lumo-tint-5pct: hsla(214, 65%, 85%, 0.06);
      --lumo-tint-10pct: hsla(214, 60%, 80%, 0.14);
      --lumo-tint-20pct: hsla(214, 64%, 82%, 0.23);
      --lumo-tint-30pct: hsla(214, 69%, 84%, 0.32);
      --lumo-tint-40pct: hsla(214, 73%, 86%, 0.41);
      --lumo-tint-50pct: hsla(214, 78%, 88%, 0.5);
      --lumo-tint-60pct: hsla(214, 82%, 90%, 0.58);
      --lumo-tint-70pct: hsla(214, 87%, 92%, 0.69);
      --lumo-tint-80pct: hsla(214, 91%, 94%, 0.8);
      --lumo-tint-90pct: hsla(214, 96%, 96%, 0.9);
      --lumo-tint: hsl(214, 100%, 98%);
      --lumo-shade-5pct: hsla(214, 0%, 0%, 0.07);
      --lumo-shade-10pct: hsla(214, 4%, 2%, 0.15);
      --lumo-shade-20pct: hsla(214, 8%, 4%, 0.23);
      --lumo-shade-30pct: hsla(214, 12%, 6%, 0.32);
      --lumo-shade-40pct: hsla(214, 16%, 8%, 0.41);
      --lumo-shade-50pct: hsla(214, 20%, 10%, 0.5);
      --lumo-shade-60pct: hsla(214, 24%, 12%, 0.6);
      --lumo-shade-70pct: hsla(214, 28%, 13%, 0.7);
      --lumo-shade-80pct: hsla(214, 32%, 13%, 0.8);
      --lumo-shade-90pct: hsla(214, 33%, 13%, 0.9);
      --lumo-shade: hsl(214, 33%, 13%);
      --lumo-contrast-5pct: var(--lumo-tint-5pct);
      --lumo-contrast-10pct: var(--lumo-tint-10pct);
      --lumo-contrast-20pct: var(--lumo-tint-20pct);
      --lumo-contrast-30pct: var(--lumo-tint-30pct);
      --lumo-contrast-40pct: var(--lumo-tint-40pct);
      --lumo-contrast-50pct: var(--lumo-tint-50pct);
      --lumo-contrast-60pct: var(--lumo-tint-60pct);
      --lumo-contrast-70pct: var(--lumo-tint-70pct);
      --lumo-contrast-80pct: var(--lumo-tint-80pct);
      --lumo-contrast-90pct: var(--lumo-tint-90pct);
      --lumo-contrast: var(--lumo-tint);
      --lumo-header-text-color: var(--lumo-contrast);
      --lumo-body-text-color: var(--lumo-contrast-90pct);
      --lumo-secondary-text-color: var(--lumo-contrast-70pct);
      --lumo-tertiary-text-color: var(--lumo-contrast-50pct);
      --lumo-disabled-text-color: var(--lumo-contrast-70pct);
      --lumo-primary-color-50pct: #f8029c70;
      --lumo-primary-color-10pct: #f8029c30;
      --lumo-primary-contrast-color: #fff;
      --lumo-error-color: hsl(3, 79%, 49%);
      --lumo-error-color-50pct: hsla(3, 75%, 62%, 0.5);
      --lumo-error-color-10pct: hsla(3, 75%, 62%, 0.14);
      --lumo-error-text-color: hsl(3, 100%, 80%);
      --lumo-success-color: #16ffcf;
      --lumo-success-color-50pct: #16ffcf50;
      --lumo-success-color-10pct: #16ffcf10;
      --lumo-success-text-color: #006550;
    }

    vaadin-tabsheet::part(tabs-container) {
      box-shadow: none;
    }

    vaadin-tab[selected] {
      background-color: #0000;
      color: white;
      border-color: #0000;
      transition: all 2s ease 0s;
    }

    vaadin-tab .tab-name {
      z-index: 3;
    }

    vaadin-tab {
      border-radius: 0.5rem;
    }

    vaadin-tab::before {
      background-image: linear-gradient(
        310deg,
        #7928ca 0%,
        #ff0080 100%
      ) !important;
      height: 100%;
      width: 100%;
    }

    vaadin-tabs {
      border-radius: 0.25rem;
      background: #111322;
      flex: none;
      align-self: stretch;
      min-width: 8em;
      margin: auto;
      padding: 0.25rem;
    }

    vaadin-tabs::part(tabs) {
      margin: 0;
    }

    vaadin-tabs::part(back-button) {
      display: none;
    }

    vaadin-tabs::part(forward-button) {
      display: none;
    }

    vaadin-tabs[orientation='horizontal'] {
      margin: auto;
    }

    vaadin-text-field::part(input-field) {
      background: #242848;
      border-radius: 8px;
      color: #c5c8e2;
      border: 1px solid var(--lumo-contrast-20pct);
      height: 32px;
      font-size: 12px;
      line-height: 16.37px;
      letter-spacing: -0.02em;
    }

    vaadin-text-field::part(helper-text) {
      font-size: 12px;
    }

    vaadin-select::part(input-field),
    vaadin-multi-select-combo-box::part(input-field) {
      height: 32px;
    }
  `,
];

vaadinStyles.register = () => {
  // Otherwise dropdown background of vaadin-select is white
  registerStyles('vaadin-select', vaadinStyles);
  registerStyles('vaadin-select-overlay', vaadinStyles);
  registerStyles('vaadin-tabs', vaadinStyles);
  registerStyles('vaadin-tab', vaadinStyles);
  registerStyles('vaadin-multi-select-combo-box-overlay', vaadinStyles);
  registerStyles('vaadin-date-picker', vaadinStyles);
  registerStyles('vaadin-date-picker-overlay', vaadinStyles);
  registerStyles('vaadin-combo-box', vaadinStyles);
  registerStyles('vaadin-combo-box-overlay', vaadinStyles);
  registerStyles('vaadin-checkbox', vaadinStyles);
  registerStyles('vaadin-menu-bar-overlay', vaadinStyles);
  registerStyles('vaadin-accordion', vaadinStyles);
  registerStyles('vaadin-text-field', vaadinStyles);

  registerStyles(
    'vaadin-notification-card',
    css`
      [part='content'] {
        padding: 0.25rem 1rem;
      }
    `
  );

  registerStyles(
    'vaadin-select-overlay',
    css`
      [part='overlay'] {
        border-radius: var(--nn-elements-default-border-radius);
      }
    `
  );
  registerStyles(
    'vaadin-multi-select-combo-box-overlay',
    css`
      [part='overlay'] {
        border-radius: var(--nn-elements-default-border-radius);
        background: var(--lumo-base-color);
      }
    `
  );
  registerStyles(
    'vaadin-select-item',
    css`
      [part='content'] {
        color: var(--nn-primary-text-color);
      }
    `
  );
  registerStyles(
    'vaadin-select',
    css`
      [part='input-field'] {
        color: #fff;
        border-radius: var(--nn-elements-default-border-radius);
        background: #343a68;
      }
      [has-value] {
        background-image: linear-gradient(310deg, #7928ca 0%, #ff0080 100%);
      }
      [part='label'] {
        color: var(
          --nn-primary-text-color
        ) !important; /** FIXME: important needed for hover state. */
        font-weight: 300;
      }
      [part='toggle-button'] {
        color: var(--nn-primary-text-color);
      }
    `
  );
  registerStyles(
    'vaadin-multi-select-combo-box',
    css`
      [part='input-field'] {
        color: #fff;
        border-radius: var(--nn-elements-default-border-radius);
        background: #343a68;
      }
      [has-value] [part='input-field'] {
        background-image: linear-gradient(310deg, #7928ca 0%, #ff0080 100%);
      }
      [part='label'] {
        color: var(
          --nn-primary-text-color
        ) !important; /** FIXME: important needed for hover state. */
        font-weight: 300;
      }
      [part='toggle-button'] {
        color: var(--nn-primary-text-color);
      }
    `
  );
  registerStyles(
    'vaadin-multi-select-combo-box-chip',
    css`
      [part='remove-button'],
      [part='label'] {
        color: var(--nn-primary-text-color);
      }
    `
  );
  registerStyles(
    'vaadin-date-picker',
    css`
      [part='input-field'] {
        border-radius: var(--nn-elements-default-border-radius);
        background-image: linear-gradient(310deg, #7928ca 0%, #ff0080 100%);
      }
      [part='label'] {
        color: var(
          --nn-primary-text-color
        ) !important; /** FIXME: important needed for hover state. */
        font-weight: 300;
      }
    `
  );
  registerStyles(
    'vaadin-text-field',
    css`
      :host {
        --vaadin-input-field-border-width: 1px;
        --vaadin-input-field-border-color: var(--lumo-contrast-20pct);
      }

      [part='input-field'] {
        background: #242848;
        border-radius: 8px;
        color: #c5c8e2;
      }
    `
  );
  registerStyles(
    'vaadin-date-picker-overlay',
    css`
      [part='toggle-button'] {
        color: var(--nn-primary-text-color);
      }
    `
  );
  registerStyles(
    'vaadin-combo-box',
    css`
      [part='input-field'] {
        color: #fff;
        border-radius: var(--nn-elements-default-border-radius);
        background: #343a68;
      }

      [part='label'] {
        color: var(
          --nn-primary-text-color
        ) !important; /** FIXME: important needed for hover state. */
        font-weight: 300;
      }
    `
  );
  registerStyles(
    `vaadin-combo-box[has-value]`,
    css`
      [part='input-field'] {
        background-image: linear-gradient(310deg, #7928ca 0%, #ff0080 100%);
      }
    `
  );
  registerStyles(
    'vaadin-combo-box-overlay',
    css`
      [part='overlay'] {
        border-radius: var(--nn-elements-default-border-radius);
        background: var(--lumo-base-color);
      }
    `
  );
  registerStyles(
    'vaadin-menu-bar',
    css`
      ::slotted(vaadin-menu-bar-button) {
        min-width: 36px;
        width: 36px;
        margin: 0;
        padding: 0;
        background-color: transparent;
      }
    `
  );
  registerStyles(
    'vaadin-menu-bar-button',
    css`
      [part='label'] ::slotted(vaadin-menu-bar-item) {
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
    `
  );

  registerStyles(
    'vaadin-menu-bar-list-box',
    css`
      [part='items'] ::slotted([role='menuitem']) {
        cursor: pointer;
      }
    `
  );
  registerStyles(
    'vaadin-menu-bar-overlay',
    css`
      [part='overlay'] {
        border-radius: var(--nn-elements-default-border-radius);
        background: var(--lumo-base-color);
      }
    `
  );
};

vaadinStyles.register();

export { vaadinStyles };
