/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Project } from '../../types/index';

// Define a type for the slice state
interface ProjectState {
  current: Project | null;
}

// Define the initial state using that type
const initialState: ProjectState = {
  current: null,
};

const projectSlice = createSlice({
  name: 'project',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentProject: (state, action: PayloadAction<Project | null>) => {
      state.current = action.payload;
    },
    clearCurrentProject: state => {
      state.current = null;
    },
  },
});

const { setCurrentProject, clearCurrentProject } = projectSlice.actions;
const projectReducer = projectSlice.reducer;

export { ProjectState, projectReducer, setCurrentProject, clearCurrentProject };
