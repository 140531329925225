/* eslint-disable class-methods-use-this */
import { getClaimsAccounts } from '../../modules/claims';
import { setAccounts } from '../../state/slices/accounts';
import { store } from '../../state/store';

export class ClaimsAccountsService {
  async getClaims() {
    const resultClaimsAccounts = await getClaimsAccounts();

    if (resultClaimsAccounts.length === 0) {
      store.dispatch(setAccounts([]));
    } else {
      store.dispatch(setAccounts(resultClaimsAccounts));
    }

    return resultClaimsAccounts;
  }
}
