import { BrowserCacheLocation } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: process.env.CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.TENANT_ID}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

export { msalConfig };
