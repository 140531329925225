/* eslint-disable no-param-reassign */
import { getQueryParams } from '@mch/nn-web-viz/dist/packages/router/utils';
import { axios, Axios } from '@bundled-es-modules/axios';
import { delay } from '../../utils';
import { HASURA_ENDPOINT } from '../../constants';

const getToken = async () => {
  const { store } = await import('../../state/store');
  let token: string | undefined | null =
    store.getState().accounts?.currentAccount?.tokens?.hasuraToken;

  if (token != null) {
    return token;
  }
  token = getQueryParams().get('hasuraToken');
  return token;
};

async function getHasuraToken() {
  const token = await getToken();

  if (token != null) {
    return token;
  }

  await delay(500);

  return getHasuraToken();
}

class HasuraClient {
  private client: Axios | null = null;

  public initComplete: Promise<void>;

  public hasuraToken: string | null = null;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async getTokens() {
    this.hasuraToken = await getHasuraToken();
  }

  async _setAxiosInstance() {
    await this.getTokens();
    this.client = axios.create({
      baseURL: HASURA_ENDPOINT,
    });

    this.client.interceptors.request.use(
      async config => {
        await this.getTokens();
        config.headers.Authorization = `Bearer ${this.hasuraToken}`;

        return config;
      },
      error => Promise.reject(error)
    );
  }

  async load(qry, variables) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Hasura client not initialized');
    }

    const response = await this.client.post('/v1/graphql', {
      query: qry,
      variables,
    });

    if (response.data.errors && response.data.errors.length > 0) {
      throw new Error(response.data.errors[0].message);
    }

    return response.data.data;
  }
}

const hasuraClient = new HasuraClient();

export { hasuraClient, HasuraClient, getHasuraToken };
