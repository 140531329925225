import { connect } from 'pwa-helpers/connect-mixin';

import { configureStore } from '@reduxjs/toolkit';
import { accountsReducer } from './slices/accounts';
import { userSettingsReducer } from './slices/userSettings';
import { apiService } from './slices/api';
import { userReducer } from './slices/user';
import { tokenReducer } from './slices/token';
import { projectListReducer } from './slices/projectList';
import { projectInfoReducer } from './slices/projectInfo';
import { projectReducer } from './slices/project';
import { claimsReducer } from './slices/claims';
import { filtersReducer } from './slices/filters';
import { verbatimReducer } from './slices/verbatim';

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    accounts: accountsReducer,
    userSettings: userSettingsReducer,
    user: userReducer,
    token: tokenReducer,
    projectList: projectListReducer,
    projectInfo: projectInfoReducer,
    [apiService.reducerPath]: apiService.reducer,
    claims: claimsReducer,
    filters: filtersReducer,
    verbatim: verbatimReducer,
    project: projectReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiService.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;

export { RootState, AppDispatch, store, connect };
