import { hasura, hasuraQueries } from '../../modules/hasura';
import { setProjectList } from '../../state/slices/projectList';
import { store } from '../../state/store';

export class ProjectsService {
  async getProjectList() {
    const result: any = await hasura.query(hasuraQueries.getProjectList());

    store.dispatch(setProjectList(result?.project));
  }
}
