import { connect } from 'pwa-helpers/connect-mixin';

import { configureStore } from '@reduxjs/toolkit';
import { appConfigReducer } from './slices/appConfig';
import { userSettingsReducer } from './slices/userSettings';
import { apiService } from './slices/api';
import { userReducer } from './slices/user';
import { tokenReducer } from './slices/token';
import { projectListReducer } from './slices/projectList';
import { projectInfoReducer } from './slices/projectInfo';
import { claimsReducer } from './slices/claims';
import { filtersReducer } from './slices/filters';

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    appConfig: appConfigReducer,
    userSettings: userSettingsReducer,
    user: userReducer,
    token: tokenReducer,
    projectList: projectListReducer,
    projectInfo: projectInfoReducer,
    [apiService.reducerPath]: apiService.reducer,
    claims: claimsReducer,
    filters: filtersReducer,
  },
  // @ts-ignore
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat(apiService.middleware);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;

export { RootState, AppDispatch, store, connect };
